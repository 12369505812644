import { commonActionTypes as actionTypes } from '../ActionTypes/commonActionTypes'
import alertTypes from '../Constants/alertTypes'
import appointmentActions from './appointmentActions'
import { httpFetch } from '../Utils/httpUtils'
import leadActions from './leadActions';
import sorting from '../Utils/sorting'
import helpers from '../Utils/helpers'
import * as formValidationRules from '../Constants/formValidationRules'

const commonActions = {
    showError(message, title) {
        return {
            type: actionTypes.SHOW_ALERT,
            message,
            alertType: alertTypes.error,
            title: title || 'Oh snap!'
        }
    },

    showWarning(message, title, timeout) {
        return {
            type: actionTypes.SHOW_ALERT,
            message,
            alertType: alertTypes.warning,
            title: title || 'Warning!',
            timeout: timeout
        }
    },

    showSuccess(message, title, timeout) {
        return {
            type: actionTypes.SHOW_ALERT,
            message,
            alertType: alertTypes.success,
            title: title || 'Well done!',
            timeout: timeout
        }
    },

    hideAlert() {
        return {
            type: actionTypes.HIDE_ALERT
        }
    },

    progressLoader(showComponent) {
        return {
            type: actionTypes.LOADER_COMPONENT_VISIBILITY,
            showComponent
        }
    },
    
    toggleSideMenu(value) {
        return {
            type: actionTypes.TOGGLE_SIDE_MENU,
            value
        }
    },

    getUserInfo() {
        return dispatch => {
            httpFetch('/api/user/getinfo/', dispatch)
                .then(json => {
                    dispatch({
                        type: actionTypes.STORE_USER_INFO,
                        user: json
                    })

                    dispatch(appointmentActions.getOpenAppointmentsCount())
                    dispatch(leadActions.getOpenLeadsCount())
                    dispatch(commonActions.loadDropdownList('CustomerGroup, CustomerType, CustomerStatus, CustomerGroup2, SpcsEdi, CompensationReasons, CompensationMonths, DesktopVersion, TargetGroup, VonServices'))
                    dispatch(commonActions.getShortcuts())
                    dispatch(commonActions.getUserSignature())
                    dispatch({type: actionTypes.FINISH_AUTHENTICATION})
                    dispatch(commonActions.getNotifications())
                })
                .catch(error => {}) // Not logged in.
        }
    },

    reset() {
        return dispatch => {
            dispatch({ type: actionTypes.RESET_COMMON });
            dispatch({ type: actionTypes.FINISH_AUTHENTICATION })
        }
    },

    searchUser(input) {
        return dispatch => {
            return httpFetch(`/api/user/search/${input}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.USER_SEARCH_RESULTS, data: json });
                })
        }
    },

    searchCustomer(input) {
        input = input.split("/").length > 1 ? input.split("/")[0].trim() : input;
        return dispatch => {
            return httpFetch(`/api/customer/SearchByNameOrNumber/${input}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.CUSTOMER_SEARCH_RESULTS, data: json });
                })
        }
    },

    searchVossCustomer(input) {
        input = input.split("/").length > 1 ? input.split("/")[0].trim() : input;
        return dispatch => {
            return httpFetch(`/api/customer/SearchVossCustomers/${input}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.VOSS_CUSTOMER_SEARCH_RESULTS, data: json });
                })
        }
    },

    searchReseller(input) {
        input = input.split("/").length > 1 ? input.split("/")[0].trim() : input;
        return dispatch => {
            return httpFetch(`/api/customer/SearchReseller/${input}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.RESELLER_SEARCH_RESULTS, data: json });
                })
        }
    },

    searchCompanyExpensePartner(input) {
        input = input.split("/").length > 1 ? input.split("/")[0].trim() : input;
        return dispatch => {
            return httpFetch(`/api/companyexpense/SearchCompanyExpensePartner/${input}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.COMPANY_EXPENSE_PARTNER_SEARCH_RESULTS, data: json });
                })
        }
    },

    setOpenHeader(headerTitle){
        return {
            type: actionTypes.SET_OPEN_HEADER,
            headerTitle
        }
    },

    loadDropdownList(listname, callback) {
        return dispatch => {
            httpFetch('/api/generic/getdropdownlist/' + listname, dispatch)
                .then(json => {
                    for (let [key, list] of Object.entries(json)) {
                        dispatch(commonActions.storeDropDownList(key, list));
                    }

                    if (callback) {
                        callback();
                    }
                })
        }
    },

    storeDropDownList(listname, list) {
        return {
            type: actionTypes.LOAD_DROPDOWN_LIST,
            listname,
            list
        }
    },

    notifyUser(message, isShown) {
        return {
            type: actionTypes.PUSH_NOTIFICATION,
            message, isShown
        }
    },

    showModal(isShown) {
        return {
            type: actionTypes.SHOW_MODAL,
            isShown
        }
    },

    signOut(callback) {
        return dispatch => {
            httpFetch('/api/auth/signout/')
                .then(json => {
                    dispatch(commonActions.reset())
                    if (callback) {
                        callback();
                    }
                })
        }
    },

    getStartupParameters() {
        return dispatch => {
            httpFetch('/api/featuretoggles/index', dispatch)
                .then(json => {
                    dispatch({
                        type: actionTypes.SET_STARTUP_PARAMETERS,
                        payload: json
                    })
                })
        }
    },
    
    getExternalUrls() {
        return dispatch => {
            httpFetch('/api/externalurls/index', dispatch)
                .then(json => {
                    dispatch(commonActions.setExternalUrls(json))
                })
        }
    },

    setExternalUrls(urls) {
        return {
            type: actionTypes.SET_EXTERNAL_URLS,
            payload: urls
        }
    },

    startFormEditing(form, data, validationRules) {
        return {type: actionTypes.START_EDITING_FORM, form, data, validationRules}
    },

    cancelFormEditing(formId, callback) {
        return dispatch => {
            dispatch({type: actionTypes.CANCEL_EDITING_FORM, formId});

            if (callback) {
                callback();
            }
        }
    },

    changeMultiFormField(form, field, value) {
        return {
            type: actionTypes.CHANGE_FORM_FIELD_VALUE,
            form, field, value
        }
    },

    setFieldErrorFlags(form, fieldErrorFlags) {
        return {
            type: actionTypes.STORE_FIELD_ERROR_FLAGS,
            form, fieldErrorFlags
        }
    },

    changeFormArrayField(form, field, arr, index, value) {
        let copy = arr.slice(0);
        
        if (value === '-1') {
            copy.splice(index, 1);
        } else {
            copy[index] = value;
        }

        return commonActions.changeMultiFormField(form, field, copy);
    },

    changeMultiBudgetFormField(form, field, value) {
        return {
            type: actionTypes.CHANGE_BUDGET_FORM_FIELD_VALUE,
            form, field, value
        }
    },

    changeBudgetFormArrayField(form, field, arr, index, value) {

        arr[index][field] = value;

        return dispatch => {
            dispatch(commonActions.changeMultiBudgetFormField(form, field, arr[index][field]));
        }
    },

    showVonWarning(isShown) {
        return {
            type: actionTypes.SHOW_VON_WARNING,
            isShown
        }
    },

    getStatus() {
        return dispatch => {
            httpFetch('/api/status', dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.SAVE_STATUS, payload: json })
                })
        }
    },

    sortListByKey(list, key, listName) {
        return {
            type: actionTypes.SORTED_LIST,
            listName: listName,
            list: sorting.sortListByKey(list, key, listName)
        }
    },

    clearStoreSelected() {
        return {
            type: actionTypes.STORE_SELECTED,
            data: { customerNumber: null, type: null, typeId: null }
        };
    },

    storeDemoProduct(value) {
        return { type: actionTypes.STORE_DEMO_PRODUCT, value: value }
    },

    storeDesktopVersion(value) {
        return { type: actionTypes.STORE_DESKTOP_VERSION, value: value }
    },

    getSendDemoEmailTemplate(customerNumber, productId) {
        return (dispatch) => {

            if (!customerNumber || customerNumber.includes("/"))
                customerNumber = null;

            return httpFetch(`/api/demo/getSendDemoEmailTemplate/${productId}/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("sendDemoEmail", json, formValidationRules.EmailTemplate));
                    dispatch(commonActions.loadDropdownList("DemoProducts"));
                    dispatch(commonActions.storeDemoProduct(productId));
                })
        }
    },

    getSendDesktopVersionsEmailTemplate(customerNumber, productId) {
        return (dispatch) => {

            if (!customerNumber || customerNumber.includes("/"))
                customerNumber = null;

            return httpFetch(`/api/desktopVersion/getVersionEmailTemplate/${productId}/${customerNumber}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("sendDesktopVersionEmail", json, formValidationRules.EmailTemplate));
                    dispatch(commonActions.loadDropdownList("DesktopVersion"));
                    dispatch(commonActions.storeDesktopVersion(productId));
                })
        }
    },

    getTransactionServiceBySalesNumber(salesNumber) {
        return dispatch => {
            return httpFetch(`/api/transactionServices/getTransactionServiceBySalesNumber/${salesNumber}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_TRANSACTION_SERVICES, data: json });
                })
        }
    },

    getTransactionServiceByTeamId(teamId) {
        return dispatch => {
            return httpFetch(`/api/transactionServices/getTransactionServiceByTeamId/${teamId}`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_TRANSACTION_SERVICES_TEAM, data: json });
                })
        }
    },

    sendDemoEmail(emailForm, productId, type, typeId, callback) {
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(emailForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(emailForm.data)
            }

            dispatch(commonActions.progressLoader(true));

            if (!typeId)
                typeId = 0;

            return httpFetch(`/api/demo/SendDemoEmail/${productId}/${type}/${typeId}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Email has been sent'));

                    callback();
            })
        }
    },

    getShortcuts() {
        return dispatch => {
            httpFetch(`/api/user/getusershortcuts/`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_USER_SHORTCUTS, shortcuts: json });
                })
        }
    },

    getUserSignature() {
        return dispatch => {
            httpFetch(`/api/user/getUserSignature/`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_USER_SIGNATURE, userSignature: json });
                })
        }
    },

    getUserSignatureForm() {
        return dispatch => {
            httpFetch(`/api/user/getUserSignature/`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("userSignatureForm", json, null))
                })
        }
    },

    updateMySignature(form, callback) {
        return dispatch => {
            dispatch(commonActions.progressLoader(true));

            var regex = /(<([^>]+)>)/ig
            form.data.noHtml = form.data && form.data.signature && form.data.signature.replace(regex, '');

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch(`/api/user/updateMySignature/`, dispatch, httpRequest)
                .then(() => {
                    dispatch(commonActions.showSuccess('Signature has been updated'));
                    dispatch(commonActions.getUserSignature())
                    callback();
                })
        }
    },

    modifyShortcuts(shortcuts) {
        const httpRequest = {
            method: 'POST',
            body: JSON.stringify(shortcuts)
        }

        return dispatch => {
            httpFetch(`/api/user/modifyShortcuts/`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess('Shortcuts have been successfully updated!'));
                    this.getShortcuts()
                })
        }
    },

    togglePong(pongToggle) {
        return { type: actionTypes.TOGGLE_PONG, pongToggle };
    },

    clearUserSearch() {
        return { type: actionTypes.USER_SEARCH_RESULTS, data: [] };
    },
    
    clearCustomerSearch() {
        return { type: actionTypes.CUSTOMER_SEARCH_RESULTS, data: [] };
    },

    getNotifications() {
        return dispatch => {
            httpFetch(`/api/notification/getNotifications`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.STORE_NOTIFICATIONS, data: json});
                    commonActions.updateLocalStorageNotifications(json);
                })
        }
    },

    getReleaseVersions() {
        return dispatch => {
            httpFetch(`/api/releaseNews/getAll`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.STORE_RELEASE_VERSION_ALL, data: json})
                })
        }
    },

    getVersionById(versionId) {
        return dispatch => {
            httpFetch(`/api/releaseNews/get/${versionId}`, dispatch)
                .then(json => {
                    dispatch({type: actionTypes.STORE_RELEASE_VERSION, data: json})
                })
        }
    },

    updateLocalStorageNotifications(dbNotifications) {
        let localNotifications = localStorage.Notifications ? JSON.parse(localStorage.Notifications) : [];

        dbNotifications.map((item) => {
            let index = localNotifications.findIndex(element => element.id === item.id);

            const notification = {
                id : item.id,
                read : false,
                title: item.title,
                created: item.created,
                link: item.link
            }

            if (index === -1) {
                localNotifications.push(notification);
            }
            else
            {
                notification.read = localNotifications[index].read;

                localNotifications[index] = notification;
            }
        });

        const updatedList = localNotifications.filter(x => dbNotifications.find(db => db.id === x.id));

        localStorage.Notifications = JSON.stringify(updatedList);

    },

    getEducationInformationTemplate(educationInfoHtmlString){
        return dispatch => {
            httpFetch(`/api/education/geteducationemailtemplate`)
                .then(json => {
                    json.body = json.body.replace("[courseInfo]", educationInfoHtmlString);
                    dispatch(commonActions.startFormEditing("sendEducationInformationForm", json, formValidationRules.EmailTemplate));
                })
        }
    },

    sendEducationInformation(educationForm){
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(educationForm);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(educationForm.data)
            }

            dispatch(commonActions.progressLoader(true));

            return httpFetch('/api/education/send', dispatch, httpRequest)
                    .then(json => {
                        dispatch(commonActions.showSuccess('Information has been sent'));
                        dispatch(commonActions.cancelFormEditing("sendEducationInformationForm"));
                    })
        }
    },

    getMyNonCustomerSignature() {
        return dispatch => {
            return httpFetch(`/api/noncustomer/mySignature`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("nonCustomerMySignature", json, null))
                })
        }
    },

    updateMyNonCustomerSignature(form, callback) {
        return dispatch => {
            dispatch(commonActions.progressLoader(true));

            var regex = /(<([^>]+)>)/ig
            form.data.noHtml = form.data && form.data.signature && form.data.signature.replace(regex, '');

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }
            
            return httpFetch(`/api/noncustomer/updateMySignature/`, dispatch, httpRequest)
                .then(() => {
                    dispatch(commonActions.showSuccess('Signature has been updated'));
                    callback();
                })
        }
    },

    saveMyNonCustomerTemplate(form, callback) {
        return dispatch => {
            dispatch(commonActions.progressLoader(true));

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(form.data)
            }

            return httpFetch(`/api/noncustomer/saveMyTemplate/`, dispatch, httpRequest)
                .then(() => {
                    dispatch(commonActions.showSuccess(`Template has been ${form.data.templateId > 0 ? 'updated' : 'created'}`));
                    dispatch(commonActions.loadDropdownList("NonCustomerTemplates"));
                    callback();
                })
        }
    },

    getMyNonCustomerTemplate(id) {
        return dispatch => {
            return httpFetch(`/api/noncustomer/myEmailTemplate/${id}`, dispatch)
                .then(json => {
                    dispatch(commonActions.startFormEditing("nonCustomerMyTemplate", json, null));
                })
        }
    },

    deleteMyNonCustomerTemplate(id, callback) {
        const httpRequest = { method: 'DELETE' }

        return dispatch => {
            return httpFetch(`/api/noncustomer/deleteMyTemplate/${id}`, dispatch, httpRequest)
                .then(json => {
                    dispatch(commonActions.showSuccess(`Template has been deleted`));
                    dispatch(commonActions.loadDropdownList("NonCustomerTemplates"));
                    callback();
                })
        }
    },

    getEmailTemplate(id) {
        return (dispatch) => {
            return httpFetch(`/api/noncustomer/emailTemplate/${id}`, dispatch)
                .then(json => {
                    json.emailTemplate = json.name
                    json.defaultFrom = json.from

                    dispatch(commonActions.startFormEditing("nonCustomerEmail", json, formValidationRules.EmailTemplate))
                })
        }
    },

    startNonCustomerEmailSendingModal() {
        return (dispatch) => {
            var body = {
                to: '',
                from: 'byra.spcs@visma.com',
                subject: '',
                body: ''
            }
                dispatch(commonActions.startFormEditing("nonCustomerEmail", body, formValidationRules.EmailTemplate))
        }
    },

    sendNonCustomerEmail(form, appointment, callback) {
        return dispatch => {

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify({ email: form.data, appointment: appointment ? appointment.data : "" })
            }

            let validationResult = ""

            validationResult += (helpers.validateForm(form));

            if (appointment)
                validationResult += (validationResult.length > 0 ?  "\n" + helpers.validateForm(appointment) : ("" + helpers.validateForm(appointment)))

            if (validationResult > 0) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            return httpFetch(`/api/noncustomer/sendNonCustomerEmail/`, dispatch, httpRequest)
                .then(() => {
                    dispatch(commonActions.showSuccess('Email has been sent'));
                    callback();
                })
        }
    },

    getMovieMails() {
        return dispatch => {
            return httpFetch(`/api/movie/getMovieMails/`, dispatch)
                .then(json => {
                    dispatch({ type: actionTypes.STORE_MOVIE_MAILS, data: json });
                })
        }
    },

    updateMovieMail(movieMail, callback){
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(movieMail);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(movieMail.data)
            }

            dispatch(commonActions.progressLoader(true));

            return httpFetch('/api/movie/updateMovieMail', dispatch, httpRequest)
                    .then(json => {
                        dispatch(commonActions.showSuccess('Movie mail has been saved'));
                        dispatch(commonActions.getMovieMails())
                        callback();
                    })
        }
    },

    createMovieMail(movieMail, callback){
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(movieMail);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(movieMail.data)
            }

            dispatch(commonActions.progressLoader(true));

            return httpFetch('/api/movie/createMovieMail', dispatch, httpRequest)
                    .then(json => {
                        dispatch(commonActions.showSuccess('Movie mail has been created'));
                        dispatch(commonActions.getMovieMails())
                        callback();
                    })
        }
    },

    sendMovieMail(movieMail, callback){
        return dispatch => {
            dispatch(commonActions.hideAlert());

            const validationResult = helpers.validateForm(movieMail);

            if (validationResult) {
                dispatch(commonActions.showWarning(validationResult, 'Please validate!'));
                return null;
            }

            const httpRequest = {
                method: 'POST',
                body: JSON.stringify(movieMail.data)
            }
            dispatch(commonActions.progressLoader(true));
            return httpFetch('/api/movie/sendMovieMail', dispatch, httpRequest)
                    .then(json => {
                        dispatch(commonActions.showSuccess('Movie mail has been sent'));
                        callback();
                    })
        }
    },

    deleteMovieMail(id, callback){
        return dispatch => {
            dispatch(commonActions.hideAlert());
            const httpRequest = { method: 'DELETE' }

            dispatch(commonActions.progressLoader(true));
            return httpFetch(`/api/movie/deleteMovieMail/${id}`, dispatch, httpRequest)
                    .then(json => {
                        dispatch(commonActions.showSuccess('Movie mail has been deleted'));
                        dispatch(commonActions.getMovieMails())
                        callback();
                    })
        }
    },

    copyToClipboard(label, value) {
        return dispatch => {
            navigator.clipboard.writeText(value)
            return dispatch(commonActions.showSuccess(value, label + " copied to clipboard", 2000));
        }

    },

}

export default commonActions;