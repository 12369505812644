import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import commonActions from "../../../Actions/commonActions";
import customerActions from "../../../Actions/customerActions";
import Modal from "../../Modal";
import React, {useEffect, useState} from "react";
import SkynetIcon from "../../Fundamentals/SkynetIcon";
import {Dropdown} from "../../FormComponents/Dropdown";
import CustomerSearchNew from "../../Search/CustomerSearchNew";
import FormSearch from "../../Search/FormSearch";

function CustomerConnectionsEditModal ({customer, customerActions, commonActions, form, customerConnections, onClose, t, saving }) {
    const [selectedProductId, setSelectedProductId] = useState();
    const [selectedType, setSelectedType] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [current, setCurrent] = useState(customerConnections);
    const [clearSearch, setClearSearch] = useState(null);

    useEffect(() => {
        commonActions.startFormEditing("customerConnectionsForm", { toAdd: [], toDelete: [] });
    }, [])

    const onChange = (value, key) => {
        commonActions.changeMultiFormField(form.id, key, value);
    }
    
    const onDelete = (customer, index) => {
        const updatedCurrent = current.filter((_, i) => i !== index);
        const updatedToAdd = form.data.toAdd.filter(c => c.customerNumber !== customer.customerNumber && c.product !== customer.product && c.type !== customer.type);
        
        setCurrent(updatedCurrent);
        if (updatedToAdd.length < form.data.toAdd.length) {
            onChange([...updatedToAdd], "toAdd");
        } else {
            onChange([...form.data.toDelete, customer], "toDelete");
        }
            
    }
    
    const onAdd = () => {
        if (!selectedType || !selectedProductId || !selectedCustomer.customerNumber) return;
                
        let newConnectedCustomer = { 
            id: customerConnections.find(c => c.customerNumber === selectedCustomer.customerNumber && c.product === selectedProductId && c.type === selectedType)?.id,
            type: selectedType,
            customerNumber: selectedCustomer.customerNumber,
            name: selectedCustomer.name,
            organizationNumber: selectedCustomer.organizationNumber,
            product: selectedProductId
        }
        
        setCurrent([...current, newConnectedCustomer]);

        const updatedToDelete = form.data.toDelete.filter((c => c.customerNumber !== newConnectedCustomer.customerNumber && c.product !== newConnectedCustomer.product && c.type !== newConnectedCustomer.type));
        if (updatedToDelete.length === form.data.toDelete.length) {
            onChange([...form.data.toAdd, newConnectedCustomer], "toAdd");
        } else if (updatedToDelete.length < form.data.toDelete.length) {
            onChange([...updatedToDelete], "toDelete");
        }

        setSelectedProductId(null);
        setSelectedType(null);
        setSelectedCustomer(null);
        commonActions.clearCustomerSearch();
        setClearSearch(!clearSearch);
    }
    
    const handleClickSave = () => {
        customerActions.saveCustomerConnections(form, customer.vbCustomer?.customerNumber ?? customer.vossCustomer?.customerNumber, customer.vbCustomer?.id ?? customer.vossCustomer?.id, onClose); 
    }
    
    function onCancel() {
        commonActions.cancelFormEditing(form.id, onClose);
    }
    
    const modalProps = {
        title: t('customerconnections.editcustomerconnections'),
        mainButtonTitle: t('common.save'),
        onCancelClick: onCancel,
        stateful: true,
        open: true,
        onSaveClick: handleClickSave,
        customClassName: "modal-xl",
        showButtons: !saving
    }
    const customerType = (customer) => {
        let icon = "user";
        let text = "main";

        if (customer.type !== 0) {
            icon = "users";
            text = "sub";
        }
        return <><SkynetIcon icon={`vismaicon-sm vismaicon-${icon} pt-2 mr-8`} title={t("customerconnections."+text)}/>{t("customerconnections."+text)}</>
    }
    
    const productDropdown = [
        { name: "Lön Smart", id: "LonSmart" },
        { name: "Skatt & Bokslut Pro", id: "SkattBokslut" },
        { name: "eEkonomi", id: "eEkonomi" }
    ]
    
    return (
        <Modal {...modalProps}>
            {!form || saving ? <div className="spinner spinner-default-green m-auto d-block"/> : 
            <form className="skynet-form container">
                <fieldset className="d-grid col-12 gap-16 rounded-12 p-12 shadow-10">
                    <table className="table table-stripe skynet-table-no-divider">
                        <thead>
                        <tr>
                            <th>{t('customerconnections.type')}</th>
                            <th>{t('customerconnections.customer')} <small>({t('common.name')}  / {t('customerconnections.custno')} / {t('customerconnections.orgno')})</small></th>
                            <th>{t('customerconnections.product')}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {current?.map((customer, index) => (
                            <tr key={index}>
                                <th>{customerType(customer)}</th>
                                <td>{customer.name} / {customer.customerNumber} / {customer.organizationNumber}</td>
                                <td>{productDropdown.find(p => p.id === customer.product).name ?? ""}</td>
                                <td>
                                    <div className="float-right ">
                                        <SkynetIcon icon={'vismaicon-delete pr-4 vismaicon-sm'}
                                                    onClick={() => onDelete(customer, index)}/>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td className="p-12">
                                <Dropdown options={[{name: "Huvudkund", id: 0}, {name: "Kopplad kund", id: 1}]}
                                          valueProperty={"id"}
                                          property={"name"} placeholder={t('customerconnections.selecttype')}
                                          value={selectedType}
                                          onChange={(value) => setSelectedType(value)}/>
                            </td>
                            <td className="p-12">
                                <FormSearch>{(className) =>
                                    <CustomerSearchNew onChange={(customerNumber, customerName, organizationNumber) => setSelectedCustomer({
                                        customerNumber: customerNumber,
                                        name: customerName,
                                        organizationNumber: organizationNumber,
                                    })}
                                    className={className + "pb-0"}
                                    clearSearch={clearSearch}/>}
                                </FormSearch>
                            </td>
                            <td className="p-12">
                                <Dropdown options={productDropdown} valueProperty={"id"}
                                          property={"name"} placeholder={t('customerconnections.selectproduct')}
                                          value={selectedProductId}
                                          onChange={(value) => setSelectedProductId(value)}/>
                            </td>
                            <td className="">
                                <div className="float-right">
                                    <SkynetIcon icon={'vismaicon-add'} onClick={() => onAdd()}/>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </fieldset>
            </form>}
        </Modal>
);
}

function mapStateToProps(state) {
    return {
        customer: state.CustomerReducer.customer,
        customerConnections: state.CustomerReducer.customerConnections,
        form: state.CommonReducer.forms && state.CommonReducer.forms.customerConnectionsForm,
        saving: state.CommonReducer.dataLoading && state.CommonReducer.dataLoading.savecustomerConnections
    }
}


function mapDispatchToProps(dispatch) {
    return {
        commonActions: bindActionCreators(commonActions, dispatch),
        customerActions: bindActionCreators(customerActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerConnectionsEditModal));
