import { useTranslation } from "react-i18next";
import { FormInput} from '../../FormComponents/Input/Input';
import {ActionType} from "../../../Constants/actionType";
import {hasAdminAccess} from "../../../Utils/permissionUtils";
import {UserRoles} from "../../../Constants/userRoles";
import React from "react";
import SkynetIcon from "../../Fundamentals/SkynetIcon";
import CustomerSearchNew from "../../Search/CustomerSearchNew";
import FormSearch from "../../Search/FormSearch";
export function MainInfoEditContent({ commonActions, customerActions, form, actionType, user, dataLoading }) {
    const {t} = useTranslation();
    const userHasCustomerAdminRole = hasAdminAccess(user, UserRoles.Customer);
    const userHasInternalInfoRole = hasAdminAccess(user, UserRoles.InternalInfo);

    let data = form.data;

    const onChange = (field, value) => {
        commonActions.changeMultiFormField(form.id, field, value)
    }
    const onChangeContactPersonInfo = (field, value) => {
        onChange('contactPerson', {...data.contactPerson, [field]: value});
    }

    const onUpdateAddressClick = (e) => {
        e.preventDefault();
        customerActions.updateVossCustomerFromBusinessCheck(form.data?.organizationNumber, form)
    }

    return <>
        <div className="col m-0 p-0">
            <fieldset className="d-grid col-12 gap-16 rounded-12 p-12 shadow-10">
                <label className="bold">{t("maininfocontainer.contactperson")}</label>
                <FormInput label={t("maininfocontainer.firstname")} value={data.contactPerson?.firstName}
                           onChange={(input) => onChangeContactPersonInfo('firstName', input)} required={true}/>
                <FormInput label={t("maininfocontainer.lastname")} value={data.contactPerson?.lastName}
                           onChange={(input) => onChangeContactPersonInfo('lastName', input)} required={true}/>
                <FormInput label={t("maininfocontainer.emailaddress")} value={data.contactPerson?.email} type='email'
                           onChange={(input) => onChangeContactPersonInfo('email', input)} required={true}/>
                <FormInput label={t("maininfocontainer.phonenumber")} value={data.contactPerson?.phoneNumber} type='tel'
                           onChange={(input) => onChangeContactPersonInfo('phoneNumber', input)}/>
            </fieldset>
        </div>
        <div className="col m-0 p-0">
            <fieldset className="d-grid col-12 gap-16 rounded-12 p-12 shadow-10">
                <label className="bold">Address</label>
                <FormInput label={t("maininfocontainer.streetaddress")} value={data.address}
                           onChange={(input) => onChange('address', input)} required={true}/>
                <FormInput label={t("maininfocontainer.postalcode")} value={data.postalCode} type='number'
                           onChange={(input) => onChange('postalCode', input)} required={true}/>
                <FormInput label={t("maininfocontainer.postalcity")} value={data.city}
                           onChange={(input) => onChange('city', input)} required={true}/>
                <FormInput label={t("maininfocontainer.coaddress")} value={data.careOf}
                           onChange={(input) => onChange('careOf', input)}/>
                {actionType !== ActionType.Create && <div>
                    <button className="btn mt-6"
                            onClick={(e) => onUpdateAddressClick(e)}>{t("maininfocontainer.fetchaddress")}
                        <SkynetIcon icon={"vismaicon-refresh vismaicon-sm ml-16"}
                                    title={t("maininfocontainer.updateaddress")}/>
                    </button>
                    {dataLoading &&
                        <div className="spinner spinner-default-green m-auto d-block"/>}
                </div>}
            </fieldset>
        </div>

            {actionType !== ActionType.Create &&
                <fieldset className="d-grid col-12 gap-16 rounded-12 p-12 shadow-10">
                    <label className="bold">{t("maininfocontainer.companyinfo")} </label>
                    <FormInput label={t("maininfocontainer.name")} value={data.name}
                               onChange={(input) => onChange('name', input)}/>
                    {form.data?.customerNumber &&
                        <FormInput label={t("maininfocontainer.customernumber")} value={data.customerNumber}
                                   disabled={!userHasCustomerAdminRole}
                                   onChange={(input) => onChange('customerNumber', input)}/>}
                    <FormInput label={t("maininfocontainer.organizationnumber")} value={data.organizationNumber}
                               disabled={!userHasCustomerAdminRole}
                               onChange={(input) => onChange('organizationNumber', input)}/>
                    <FormSearch label={t("internalinfocontainer.invoicecustomernumber")}>{(className) =>
                        <CustomerSearchNew onChange={(input) => onChange('invoiceCustomerNumber', input)}
                                           className={className + "pb-0"}
                                           defaultSelected={form.data?.customerToBeInvoiced}
                                           disabled={!userHasInternalInfoRole}/>}
                    </FormSearch>
                    <FormInput label={t("maininfocontainer.invoiceemail")} value={data.email} type='email'
                               onChange={(input) => onChange('email', input)}/>
                </fieldset>
            }
        </>
        }