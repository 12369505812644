import {ActionType} from "../../../Constants/actionType";
import {MainInfoEditContent} from "./MainInfoEditContent";
import React from "react";
import {InternalInfoEditContent} from "./InternalInfoEditContent";

export function CustomerForm({ actionType, form, commonActions, customerActions, dataLoading, user, dropdownlists, targetGroups = [] }) {

    if (!form || dataLoading)
        return <div className="spinner spinner-default-green m-auto d-block"/>

    function mainInfo() {
        return <MainInfoEditContent commonActions={commonActions}
                                    customerActions={customerActions}
                                    actionType={actionType}
                                    form={form}
                                    user={user}
                                    dataLoading={dataLoading}/>
    }

    return <form className="skynet-form container">
        <div className="row">
            {actionType === ActionType.Edit ? <>
                <div className="col-6">
                    {mainInfo()}
                </div>
                <div className="col-6">
                    <InternalInfoEditContent
                        dropdownlists={dropdownlists}
                        commonActions={commonActions}
                        form={form}
                        actionType={actionType}
                        targetGroups={targetGroups}
                        user={user}/>
                </div>

            </> : <>
                {mainInfo()}
            </>
            }
        </div>
    </form>
}